import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Horizon Line Yacht Rentals
			</title>
			<meta name={"description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:title"} content={"Horizon Line Yacht Rentals"} />
			<meta property={"og:description"} content={"Embark on an adventure - the horizon line where the sea meets the sky"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71754926d910020edb194/images/2-4.jpg?v=2024-03-05T16:29:20.526Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link1" />
		</Components.Header1>
		<Section padding="90px 0 100px 0" quarkly-title="List-4">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
				Our Contacts
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="40px 25px"
				align-items="center"
				flex-direction="row"
				justify-content="center"
			>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						1
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							185 Metung Rd, Metung VIC 3904, Australia
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							OUR ADDRESS
						</Text>
					</Box>
				</Box>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						2
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							+61351562243
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							OUR PHONE NUMBER
						</Text>
					</Box>
				</Box>
				<Box min-width="10px" min-height="10px" display="flex">
					<Text
						margin="0px 0 0px 0px"
						display="flex"
						align-items="center"
						justify-content="center"
						font="normal 500 20px/1.2 --fontFamily-sans"
						background="#cbcfd5"
						border-radius="50%"
						width="60px"
						height="60px"
						min-width="60px"
					>
						3
					</Text>
					<Box min-width="10px" min-height="10px" margin="0px 0px 0px 24px">
						<Text margin="0px 0px 15px 0px" font="normal 500 24px/1.2 --fontFamily-sans">
							contact@bytesrise.com
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#474a4d">
							OUR EMAIL
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Services />
		<Components.Footer23 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65cddc290072400020ea2467"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});